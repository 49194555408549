export const POPUP_URL = 'https://ecom.wix.com/storefront/cartwidgetPopup';
export const CART_ICON_DSN = 'https://31e500c94d2042ff81a38a0e4a2873b7@sentry.io/1252955';
export const EMPTY_CART_GUID = '00000000-000000-000000-000000000000';
export const CART_ICON_APP_NAME = 'wixstores-cart-icon';

export const cartIconTranslationPath = (baseUrl, locale = 'en') =>
  `${baseUrl}assets/locale/cartIcon/cartIcon_${locale}.json`;

export const specs = {
  stores: {
    WixCodeAddItemsToCart: 'specs.stores.WixCodeAddItemsToCart',
    NewFedopsIntegration: 'specs.stores.NewFedopsIntegration',
    DisableCartIconLoadFonts: 'specs.stores.DisableCartIconLoadFonts',
  },
};
